var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apos-media-editor",class:{
    'apos-is-replacing': _vm.showReplace
  }},[(_vm.activeMedia)?_c('div',{staticClass:"apos-media-editor__inner"},[_c('div',{staticClass:"apos-media-editor__thumb-wrapper"},[(_vm.activeMedia.attachment && _vm.activeMedia.attachment._urls)?_c('img',{staticClass:"apos-media-editor__thumb",attrs:{"src":_vm.activeMedia.attachment._urls[_vm.restoreOnly ? 'one-sixth' : 'one-third'],"alt":_vm.activeMedia.description}}):_vm._e()]),_vm._v(" "),_c('ul',{staticClass:"apos-media-editor__details"},[(_vm.createdDate)?_c('li',{staticClass:"apos-media-editor__detail"},[_vm._v("\n        "+_vm._s(_vm.$t('apostrophe:mediaCreatedDate', { createdDate: _vm.createdDate }))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.fileSize)?_c('li',{staticClass:"apos-media-editor__detail"},[_vm._v("\n        "+_vm._s(_vm.$t('apostrophe:mediaFileSize', { fileSize: _vm.fileSize }))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.activeMedia.attachment && _vm.activeMedia.attachment.width)?_c('li',{staticClass:"apos-media-editor__detail"},[_vm._v("\n        "+_vm._s(_vm.$t('apostrophe:mediaDimensions', {
            width: _vm.activeMedia.attachment.width,
            height: _vm.activeMedia.attachment.height
          }))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('ul',{staticClass:"apos-media-editor__links"},[_c('li',{staticClass:"apos-media-editor__link",attrs:{"aria-hidden":"true"}},[_c('AposButton',{attrs:{"type":"quiet","label":"apostrophe:replace","disabled":_vm.isArchived},on:{"click":function($event){_vm.showReplace = true}}})],1),_vm._v(" "),(_vm.activeMedia.attachment && _vm.activeMedia.attachment._urls)?_c('li',{staticClass:"apos-media-editor__link"},[_c('AposButton',{attrs:{"type":"quiet","label":"apostrophe:view","disabled":_vm.isArchived},on:{"click":_vm.viewMedia}})],1):_vm._e(),_vm._v(" "),(_vm.activeMedia.attachment && _vm.activeMedia.attachment._urls)?_c('li',{staticClass:"apos-media-editor__link"},[_c('AposButton',{attrs:{"type":"quiet","label":"apostrophe:download","href":!_vm.isArchived ? _vm.activeMedia.attachment._urls.original : false,"disabled":_vm.isArchived,"download":""}})],1):_vm._e()]),_vm._v(" "),(_vm.docFields.data.title !== undefined)?_c('AposSchema',{ref:"schema",attrs:{"schema":_vm.schema,"modifiers":['small', 'inverted'],"trigger-validation":_vm.triggerValidation,"doc-id":_vm.docFields.data._id,"following-values":_vm.followingValues(),"server-errors":_vm.serverErrors},on:{"validate":_vm.triggerValidate,"reset":function($event){return _vm.$emit('modified', false)}},model:{value:(_vm.docFields),callback:function ($$v) {_vm.docFields=$$v},expression:"docFields"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('AposModalLip',{attrs:{"refresh":_vm.lipKey}},[_c('div',{staticClass:"apos-media-editor__lip"},[(!_vm.restoreOnly)?_c('AposContextMenu',{attrs:{"button":{
          label: 'apostrophe:moreOperations',
          iconOnly: true,
          icon: 'dots-vertical-icon',
          type: 'subtle',
          modifiers: [ 'small', 'no-motion' ]
        },"menu":_vm.moreMenu,"menu-placement":"top-end"},on:{"item-clicked":_vm.moreMenuHandler}}):_vm._e(),_vm._v(" "),_c('AposButton',{staticClass:"apos-media-editor__save",attrs:{"disabled":_vm.docFields.hasErrors,"label":_vm.restoreOnly ? 'apostrophe:restore' : 'apostrophe:save',"type":"primary"},on:{"click":_vm.save}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }