var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VueDraggable',_vm._b({staticClass:"apos-tree__list",attrs:{"tag":"ol","list":_vm.myRows},on:{"start":_vm.startDrag,"end":_vm.endDrag}},'VueDraggable',_vm.dragOptions,false),_vm._l((_vm.myRows),function(row){return _c('li',_vm._g({key:row._id,class:_vm.getRowClasses(row),attrs:{"data-row-id":row._id,"data-apos-tree-row":"","aria-role":_vm.options.selectable ? 'button' : null,"tabindex":_vm.options.selectable ? 0 : null}},_vm.options.selectable ? {
      'click': _vm.selectRow,
      'keydown': _vm.keydownRow
    } : {}),[_c('div',{staticClass:"apos-tree__row-data"},[(row._children && row._children.length > 0)?_c('button',{staticClass:"apos-tree__row__toggle",attrs:{"data-apos-tree-toggle":"","aria-label":_vm.$t('apostrophe:toggleSection'),"aria-expanded":!_vm.options.startCollapsed},on:{"click":function($event){return _vm.toggleSection($event)}}},[_c('AposIndicator',{staticClass:"apos-tree__row__toggle-icon",attrs:{"icon":"chevron-down-icon"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(col,index){return _c(_vm.getEffectiveType(col, row),{key:`${col.property}-${index}`,tag:"component",class:_vm.getCellClasses(col, row),style:(_vm.getCellStyles(col.property, index)),attrs:{"draft":row,"published":row._publishedDoc,"header":col,"href":(_vm.getEffectiveType(col, row) === 'a') ? row[col.property] : false,"target":col.type === 'link' ? '_blank' : false,"disabled":_vm.getCellDisabled(col, row),"data-col":col.property},on:{"click":function($event){((_vm.getEffectiveType(col, row) === 'button') && col.action) ? _vm.$emit(col.action, row._id) : null}}},[(_vm.options.draggable && index === 0 && !row.parked)?_c('AposIndicator',{staticClass:"apos-tree__row__icon apos-tree__row__icon--handle",attrs:{"icon":"drag-icon"}}):_vm._e(),_vm._v(" "),(index === 0 && row.parked && row.type !== '@apostrophecms/archive-page')?_c('AposIndicator',{staticClass:"apos-tree__row__icon apos-tree__row__icon--parked",attrs:{"icon":"lock-icon","tooltip":"apostrophe:pageIsParked"}}):_vm._e(),_vm._v(" "),(index === 0 && row.type === '@apostrophecms/archive-page')?_c('AposIndicator',{staticClass:"apos-tree__row__icon apos-tree__row__icon--parked",attrs:{"icon":"lock-icon","tooltip":"apostrophe:cannotMoveArchive"}}):_vm._e(),_vm._v(" "),(_vm.options.bulkSelect && index === 0)?_c('AposCheckbox',{staticClass:"apos-tree__row__checkbox",attrs:{"tabindex":"-1","field":{
            name: row._id,
            hideLabel: true,
            label: {
              key: 'apostrophe:toggleSelectionOf',
              title: row.title
            },
            disableFocus: true
          },"choice":{ value: row._id }},model:{value:(_vm.checkedProxy),callback:function ($$v) {_vm.checkedProxy=$$v},expression:"checkedProxy"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"apos-tree__cell__value"},[(_vm.getEffectiveIcon(col, row))?_c('AposIndicator',{staticClass:"apos-tree__cell__icon",attrs:{"icon":_vm.getEffectiveIcon(col, row),"icon-size":_vm.getEffectiveIconSize(col, row)}}):_vm._e(),_vm._v(" "),(_vm.getEffectiveCellLabel(col, row))?_c('span',{staticClass:"apos-tree__cell__label"},[_vm._v("\n            "+_vm._s(_vm.getEffectiveCellLabel(col, row))+"\n          ")]):_vm._e()],1)],1)})],2),_vm._v(" "),_c('AposTreeRows',{ref:"tree-branches",refInFor:true,class:{ 'apos-is-collapsed': _vm.options.startCollapsed },style:({
        'max-height': _vm.options.startCollapsed ? '0' : null
      }),attrs:{"data-apos-branch-height":"","data-list-row":row._id,"rows":row._children,"headers":_vm.headers,"icons":_vm.icons,"col-widths":_vm.colWidths,"level":_vm.level + 1,"nested":_vm.nested,"list-id":row._id,"tree-id":_vm.treeId,"options":_vm.options},on:{"update":function($event){return _vm.$emit('update', $event)}},model:{value:(_vm.checkedProxy),callback:function ($$v) {_vm.checkedProxy=$$v},expression:"checkedProxy"}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }