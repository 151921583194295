var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AposModal',{staticClass:"apos-wizard apos-i18n-localize",class:{ 'apos-wizard-busy': _vm.wizard.busy },attrs:{"modal":_vm.modal},on:{"esc":_vm.close,"inactive":function($event){_vm.modal.active = false},"show-modal":function($event){_vm.modal.showModal = true},"no-modal":function($event){return _vm.$emit('safe-close')}},scopedSlots:_vm._u([{key:"leftRail",fn:function(){return [_c('AposModalBody',{staticClass:"apos-wizard__navigation",scopedSlots:_vm._u([{key:"bodyMain",fn:function(){return [_c('ul',{staticClass:"apos-wizard__navigation__items"},_vm._l((_vm.visibleSections),function(section){return _c('li',{key:section.title,staticClass:"apos-wizard__navigation__item",class:{ 'apos-is-active': _vm.isStep(section.name) }},[_vm._v("\n            "+_vm._s(section.title)+"\n          ")])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"apos-modal__footer"},[_c('AposButton',{attrs:{"type":"default","label":"apostrophe:cancel","modifiers":[ 'block' ]},on:{"click":_vm.close}})],1)]},proxy:true}])})]},proxy:true},{key:"main",fn:function(){return [_c('AposModalBody',{staticClass:"apos-wizard__content",scopedSlots:_vm._u([{key:"bodyMain",fn:function(){return [_c('header',{staticClass:"apos-wizard__header"},[_c('h2',{staticClass:"apos-modal__heading"},[_vm._v("\n            "+_vm._s(_vm.$t('apostrophe:localizeContent'))+"\n          ")])]),_vm._v(" "),_c('form',{staticClass:"apos-wizard__form",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isStep('selectContent'))?_c('fieldset',{staticClass:"apos-wizard__step apos-wizard__step-select-content"},[_c('AposInputRadio',{attrs:{"field":{
                name: 'toLocalize',
                label: 'apostrophe:selectContentToLocalize',
                choices: _vm.toLocalizeChoices
              }},model:{value:(_vm.wizard.values.toLocalize),callback:function ($$v) {_vm.$set(_vm.wizard.values, "toLocalize", $$v)},expression:"wizard.values.toLocalize"}}),_vm._v(" "),_c('p',{staticClass:"apos-wizard__help-text"},[_c('AposIndicator',{staticClass:"apos-wizard__help-text__icon",attrs:{"icon":"information-icon","icon-size":16}}),_vm._v("\n              "+_vm._s(_vm.$t('apostrophe:relatedDocsDefinition'))+"\n            ")],1)],1):_vm._e(),_vm._v(" "),(_vm.isStep('selectLocales'))?_c('fieldset',{staticClass:"apos-wizard__step apos-wizard__step-select-locales"},[_c('AposButton',_vm._g({staticClass:"apos-locale-select-all",attrs:{"label":_vm.allSelected
                ? _vm.$t('apostrophe:deselectAll')
                : _vm.$t('apostrophe:selectAll'),"type":"quiet","modifiers":[ 'inline' ]}},{ click: _vm.allSelected ? _vm.deselectAll : _vm.selectAll })),_vm._v(" "),_c('AposInputString',{ref:"searchInput",staticClass:"apos-locales-filter",attrs:{"field":_vm.searchField},on:{"input":_vm.updateFilter},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_vm._v(" "),_c('transition-group',{staticClass:"apos-selected-locales",attrs:{"tag":"ul","name":"selected-list"}},_vm._l((_vm.selectedLocales),function(loc){return _c('li',{key:loc.name,staticClass:"apos-locale-item--selected"},[_c('AposButton',{staticClass:"apos-locale-button",attrs:{"type":"primary","modifiers":[ 'small' ],"icon":"close-icon","icon-size":12,"label":loc.label},on:{"click":function($event){$event.preventDefault();return _vm.removeLocale(loc)}}})],1)}),0),_vm._v(" "),_c('ul',{staticClass:"apos-locales"},_vm._l((_vm.filteredLocales),function(loc){return _c('li',{key:loc.name,staticClass:"apos-locale-item",class:_vm.localeClasses(loc),on:{"click":function($event){return _vm.toggleLocale(loc)}}},[_c('span',{staticClass:"apos-locale"},[(_vm.isCurrentLocale(loc) && !_vm.isSelected(loc))?_c('AposIndicator',{staticClass:"apos-current-locale-icon",attrs:{"icon":"map-marker-icon","icon-size":14,"title":"Default locale","tooltip":"Current Locale"}}):_vm._e(),_vm._v(" "),(_vm.isSelected(loc))?_c('AposIndicator',{staticClass:"apos-check-icon",attrs:{"icon":"check-bold-icon","icon-size":10,"title":"Currently selected locale"}}):_vm._e(),_vm._v("\n                  "+_vm._s(loc.label)+"\n                  "),_c('span',{staticClass:"apos-locale-name"},[_vm._v("\n                    ("+_vm._s(loc.name)+")\n                  ")]),_vm._v(" "),_c('span',{directives:[{name:"apos-tooltip",rawName:"v-apos-tooltip",value:(_vm.isLocalized(loc)
                      ? 'Localized'
                      : 'Not Yet Localized'),expression:"isLocalized(loc)\n                      ? 'Localized'\n                      : 'Not Yet Localized'"}],staticClass:"apos-locale-localized",class:{
                      'apos-state-is-localized': _vm.isLocalized(loc),
                    }})],1)])}),0)],1):_vm._e(),_vm._v(" "),(_vm.isStep('confirmSettings'))?_c('fieldset',{staticClass:"apos-wizard__step apos-wizard__step-confirm-settings"},[_c('ul',{staticClass:"apos-selected-locales"},_vm._l((_vm.selectedLocales),function(loc){return _c('li',{key:loc.name,staticClass:"apos-locale-item--selected"},[_c('AposButton',{staticClass:"apos-locale-to-localize",attrs:{"type":"primary","modifiers":[ 'small' ],"label":loc.label,"disabled":true}})],1)}),0),_vm._v(" "),(_vm.wizard.values.toLocalize.data !== 'thisDoc')?_c('div',{staticClass:"apos-wizard__field-group"},[_c('p',{staticClass:"apos-wizard__field-group-heading"},[_vm._v("\n                "+_vm._s(_vm.$t('apostrophe:relatedDocSettings'))+"\n                "),_c('AposIndicator',{staticClass:"apos-wizard__field-group-heading__info",attrs:{"icon":"information-icon","icon-size":14,"tooltip":_vm.tooltips.relatedDocSettings}})],1),_vm._v(" "),_c('AposInputRadio',{attrs:{"field":{
                  name: 'relatedDocSettings',
                  choices: [
                    {
                      value: 'localizeNewRelated',
                      label: 'apostrophe:localizeNewRelated',
                    },
                    {
                      value: 'localizeAllRelatedAndOverwriteExisting',
                      label: 'apostrophe:localizeAllRelated',
                      tooltip: _vm.tooltips.localizeAllAndOverwrite,
                    },
                  ],
                }},model:{value:(_vm.wizard.values.relatedDocSettings),callback:function ($$v) {_vm.$set(_vm.wizard.values, "relatedDocSettings", $$v)},expression:"wizard.values.relatedDocSettings"}}),_vm._v(" "),(_vm.relatedDocTypes.length)?_c('AposInputCheckboxes',{attrs:{"field":_vm.relatedDocTypesField},model:{value:(_vm.wizard.values.relatedDocTypesToLocalize),callback:function ($$v) {_vm.$set(_vm.wizard.values, "relatedDocTypesToLocalize", $$v)},expression:"wizard.values.relatedDocTypesToLocalize"}}):_c('p',{staticClass:"apos-wizard__help-text"},[_c('AposIndicator',{staticClass:"apos-wizard__help-text__icon",attrs:{"icon":"lightbulb-on-icon","icon-color":"var(--a-success)","icon-size":16}}),_vm._v("\n                "+_vm._s(_vm.$t('apostrophe:noNewRelatedDocuments'))+"\n              ")],1)],1):_vm._e()]):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [(_vm.isLastStep())?_c('AposButton',{attrs:{"type":"primary","label":"apostrophe:localizeContent","disabled":!_vm.complete() || _vm.wizard.busy},on:{"click":_vm.submit}}):_c('AposButton',{attrs:{"type":"primary","icon":"arrow-right-icon","modifiers":['icon-right'],"disabled":!_vm.complete() || _vm.wizard.busy,"icon-size":12,"label":"apostrophe:next"},on:{"click":function($event){return _vm.goToNext()}}}),_vm._v(" "),(!_vm.isFirstStep())?_c('AposButton',{attrs:{"type":"default","disabled":_vm.wizard.busy,"label":"apostrophe:back"},on:{"click":function($event){return _vm.goToPrevious()}}}):_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }