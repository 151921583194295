import { render, staticRenderFns } from "./Close.vue?vue&type=template&id=2ee4614c&functional=true&"
import script from "./Close.vue?vue&type=script&lang=js&"
export * from "./Close.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports