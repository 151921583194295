import { render, staticRenderFns } from "./MenuSwap.vue?vue&type=template&id=112a0e0c&functional=true&"
import script from "./MenuSwap.vue?vue&type=script&lang=js&"
export * from "./MenuSwap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports