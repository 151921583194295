import { render, staticRenderFns } from "./AposI18nLocalizeErrors.vue?vue&type=template&id=aa5d5444&scoped=true&"
import script from "./AposI18nLocalizeErrors.vue?vue&type=script&lang=js&"
export * from "./AposI18nLocalizeErrors.vue?vue&type=script&lang=js&"
import style0 from "./AposI18nLocalizeErrors.vue?vue&type=style&index=0&id=aa5d5444&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa5d5444",
  null
  
)

export default component.exports