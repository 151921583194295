import { render, staticRenderFns } from "./FormatText.vue?vue&type=template&id=392d1dd8&functional=true&"
import script from "./FormatText.vue?vue&type=script&lang=js&"
export * from "./FormatText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports